$(function(){
    var json = {
        'E13CT' : $('#token_for_ajax').val(),
        'currentPage': location.pathname,
    };

    $.ajax({
        type: 'POST',
        url: '/api/message/unread_message_eng/',
        cache: false,
        async: true,
        data: json,
        dataType: 'json',
    }).done(function(data){
        if (data['result'] == 'error') {
            return;
        }
        var newGlobalTabCount = data['newOngoingMessageCount'];
        var statusTabOngoingCount = 0;
        var statusTabDeletedCount = 0;
        var globalTabText = '';
        var statusTabOngoingText = '';
        var statusTabDeletedText = '';
        var itemText = '';

        if (newGlobalTabCount > 0) {
            globalTabText = '応募・メッセージ管理' + '<span class="num" id="global_message_tab_count">' + newGlobalTabCount + '</span>';
        } else {
            globalTabText = '応募・メッセージ管理';
        }
        $('#global_message_tab').html(globalTabText);

        if(data['isFromMessage']) {
            statusTabOngoingCount = data['statusTabOngoingCount'];
            statusTabDeletedCount = data['statusTabDeletedCount'];

            if (statusTabOngoingCount > 0) {
                statusTabOngoingText = '現在進行中' + '<span class="num">' + statusTabOngoingCount + '</span>';
            } else {
                statusTabOngoingText = '現在進行中';
            }
            $('#tab_ongoing').html(statusTabOngoingText);

            if (statusTabDeletedCount > 0) {
                statusTabDeletedText = '削除した履歴' + '<span class="num">' + statusTabDeletedCount + '</span>';
            } else {
                statusTabDeletedText = '削除した履歴';
            }
            $('#tab_deleted').html(statusTabDeletedText);

            // 各案件コマ
            var key = '';
            if (document.URL.match(/message\/index_delete/)) {
                for(key in data['deleted']) {
                    if (key === 'totalCount') {
                        continue;
                    }
                    itemText = 'メッセージ' + '<span class="num">' + data['deleted'][key] + '</span>';
                    $('#message_eng_' + key).html(itemText);
                }
            } else if (document.URL.match(/message/)) {
                for(key in data['ongoing']) {
                    if (key === 'totalCount') {
                        continue;
                    }
                    itemText = 'メッセージ' + '<span class="num">' + data['ongoing'][key] + '</span>';
                    $('#message_eng_' + key).html(itemText);
                }
            }
        }
    }).fail(function(data){
    }).always(function(data){
    });
});
